'use strict';

angular.module('kljDigitalLibraryApp')

.controller('AccesscodeCtrl', ["$rootScope", "$scope", "$state", "$cookies", "$stateParams",
    "commonVariables", "appConfig", "SubscriptionService", "guestUserService", "guestAccountService", "guestLoginService", "AccountDetailsService",
    "OrderService", "ngToast", "Auth", "MainService",
    function($rootScope, $scope, $state, $cookies, $stateParams,
        commonVariables, appConfig, SubscriptionService, guestUserService, guestAccountService, guestLoginService, AccountDetailsService,
        OrderService, ngToast, Auth, MainService) {



        $(window).scroll(function() {
            if ($(window).width() > 767) {
                if ($(window).scrollTop() > 1) {
                    $(".menuholder").addClass("affix");
                    // alert('scroll');
                } else {
                    $(".menuholder").removeClass("affix");
                }
            }


        });
        //Scroll top
        $(document).ready(function() {
            $(this).scrollTop(0);
        });
        /*Recaptcha*/
        // var onSubmit = function(token) {
        //     console.log('success!', token);
        //     $scope.googleToken = token;
        // };
        // var onloadCallback = function() {
        //     grecaptcha.render('submit-captcha', {
        //         'sitekey': '6Ler9RoTAAAAAGIQw9A3ann6g5de5-MOWRPPxxSd',
        //         'callback': onSubmit
        //     });
        // };
        // // setTimeout(onloadCallback, 0)
        // /*End*/

        $scope.user = {}
        var init = function() {
            // getStateCityInfo();
            $scope.currentUrl = $state.current.name;
            if ($state.current.name === 'main.subscriptions') {
                getSubscriptions();
            }
            if ($stateParams.id) {
                getSubscription();

            }


            if ($state.current.name === 'main.access_code') {
                $rootScope.title = 'Accesscode Subscription';
                if ($cookies.get('token')) {
                    $state.go('index.home');
                } else {
                    getSubscriptions();
                }
                // getSubscriptionsList({
                //     criteria: "online"
                // });
            }
            console.log("state-->", $state);
        }

        var getSubscriptions = function() {
            return SubscriptionService.availablePlans()
                .then(function(response) {
                    $scope.subscriptions = response;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        var getSubscription = function() {

            return SubscriptionService.getSubscriptionDetails($stateParams.id)
                .then(function(data) {
                    $scope.planDetails = data;
                    if (data.price) {
                        $scope.order = {
                            subTotal: data.price.after_discount,
                            tax: ((data.price.after_discount * data.tax) / 100)
                        };

                        // $scope.order.total = $scope.order.subTotal + $scope.order.tax;
                    }
                    $scope.showAccesscodeInput=false;
            		$scope.confirmationBtn = true;
                })

            .catch(function(error) {

            });
        }

        var getStateCityInfo = function() {
            return MainService.getList()
                .then(function(response) {
                    $scope.citiesList = response.cities;
                    $scope.statesList = response.states;
                })
                .catch(function(error) {
                    console.log("error-->", error);
                });
        }

        // var getPrice = function(body) {
        //     var token = guestLoginService.getToken();
        //     return guestUserService.getPrice(body, token);
        // }

        var get_accesscode_order_intent_details = function(body) {

            var token = guestLoginService.getToken();
            if(!token){
            	$state.go('login')
            	return;
            }
            return guestUserService.get_order_intent(body, token);
        }


        $scope.submitForm = function(valid, details) {

            $scope.submit = true;
            if (valid) {
                AccountDetailsService.create({
                        user: details
                    })
                    .then(function(response) {
                        $scope.mobile_number = response.data.mobile_number;
                        $scope.showOTP = true;
                    })
                    .catch(function(error) {
                        console.log(error);
                        ngToast.danger('Unable to save details');
                    });
            }
        }

        function is_valid_access_code(acode){
        	if(!acode){
        		return {status: false, message:"Please Enter access code"};

        	}
        	if(acode.length!=16){
        		return {status: false, message:"Accesscode should be of 16 letters"};
        	}
        	return {status:true, message:''}
        }

        $scope.showAccesscodeInput=true;
        $scope.create_accesscode_order_intent = function() {

          	event.preventDefault();
          $scope.user.access_code = $scope.user.access_code1 + $scope.user.access_code2 + $scope.user.access_code3 + $scope.user.access_code4;
           var validity_check = is_valid_access_code($scope.user.access_code);
           if(true){
           		get_accesscode_order_intent_details({"access_code":$scope.user.access_code})
            .then(function(data){

            	console.log("data>>>>>>>>>>>>>>>>>>>>> ",data);
            	if(!data.status){
            		$scope.wrongAccesscode=true;
            		$scope.errorMessage=data.message;
            		return;
            	}
            	var obj = {id:data.subscriptionId, accesscode:$scope.user.access_code, txnid:data.txnid}
            	$scope.showAccesscodeInput=false;
            	$scope.confirmationBtn = true;
            	$state.go('main.accesscode-plan-details', obj )

            	// $scope.planDetails=data.data;
            	// $scope.showPlandetails=true;

            })
            .catch(function(err){

            	$scope.wrongAccesscode=true;
            	$scope.errorMessage = err.data.message;
            	console.log("err>>>>>>>>>>>>>>> ",err);
            })
           }
            else{
            	$scope.wrongAccesscode=true;
            	$scope.errorMessage = validity_check.message;
            }

        }

        var getPriceApiBody = function(code) {

            var body = {};
            body.subscriptionId = $stateParams.id;
            body.code = code;
            return body;
        }

        var getAccesscodeBody = function() {

            var body = {};
            // body.subscriptionId = $stateParams.id;
            body.accesscodeSub = true;
            return body;
        }

        $scope.registeredUser = function(mobile_number) {
            $scope.submitted = true;
            if (mobile_number) {

                Auth.registerdUser(mobile_number)

                .then(function(data) {

                    $scope.showOTP = true;
                    $scope.isregisteredUser = true;
                    $scope.generateOTP(mobile_number);
                })

                .catch(function(error) {
                    $scope.isregisteredUser = false;
                });
            } else {
                $scope.showOTP = false;
            }
        }

        $scope.generateOTP = function(mobile_number) {

            if (mobile_number) {

                Auth.generateOTP(mobile_number, $scope.googleToken)

                .then(function(success) {
                    // grecaptcha.reset();
                    $scope.showOTP = true;
                })

                .catch(function(error) {
                    // grecaptcha.reset();
                    console.log(error);
                });
            }
        }

        $scope.verifyOTP = function(mobile_number, otp) {
            $scope.show = true;
            if (mobile_number && otp) {
                var body = {
                    user: {
                        mobile_number: mobile_number,
                        otp: otp
                    }
                };
                guestAccountService.verifyUser(body)
                    .then(function(response) {
                        guestLoginService.addToken(response.data.token);
                        $scope.success = true;
                        // $scope.showAccesscodeInput = true;

                        $state.go('main.accesscode-plan-details', {

                        })

                    	})

                .catch(function(error) {
                	console.log("error>>>>>>>>>>>>> ",error);
                    if (error.data == 'Unauthorized') {
                        //redirect to home
                        $scope.unAuth = true;
                    } else {
                        $scope.wrongOtp = true;
                    }
                })
            }

        }

        //Make Confirmation
        $scope.confirm_subscription = function(plan, accesscode, guest) {
            var token = guestLoginService.getToken();
            $scope.payment = {};

            guestUserService.confirmOrder($stateParams.id, $state.params.txnid, $state.params.accesscode, true, token)

            .then(function(data) {
                    console.log("data----", data);
                    if(data.status){
                    	$scope.confirmSuccessMessage = true;
                    	$scope.confirmationBtn = false;
                    	return;
                    }
                    $scope.confirmErrorMessage = true;
                    $scope.errorMessage = data.message;
                    $scope.confirmationBtn = false;
                    return;

                })
                .catch(function(error) {
                	console.log("error>>>>>>>>>> ",error);

                    // $scope.confirmErrorMessage = true;
                    $scope.confirmErrorMessage = true;
                    $scope.errorMessage = error.data.message;
                    $scope.confirmationBtn = false;
                })
        }



        // ApplyCreditCardFormat($("#date"));
        init();


    }
]);

function ApplyCreditCardFormat(ele) {

            $(ele).keyup(function () {
                $(this).val($(this).val().toCardFormat());
            });

            String.prototype.toCardFormat = function () {
                return this.replace(/[^0-9A-Za-z]/g, "").substr(0, 16).split("").reduce(cardFormat, "");
                function cardFormat(str, l, i) {
                    return str + ((!i || (i % 4)) ? "" : " ") + l;
                }
            };
        }
